import { ComponentPropsWithoutRef, ReactNode } from "react"
import { v4 as uuid } from "uuid"

import { Button } from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { GameStatus, GameTrack, GarageModuleType } from "../games/types"
import {
  addPlayerAsync,
  NewPlayerAction,
  selectPlayers,
} from "../players/playersSlice"
import { addGameAsync, NewGameAction } from "../games/gamesSlice"
import { setAlert } from "../common/userSettingsSlice"

const DEMO_PLAYERS: NewPlayerAction[] = [
  {
    id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    name: "David",
  },
  {
    id: "38746af4-d425-499e-988c-8092251cb76f",
    name: "Cecilia",
  },
  {
    id: "9e3e1f4c-6571-437c-8b92-2ef102846076",
    name: "Mario",
  },
  {
    id: "41debc9f-4d74-4011-a591-4ba355ba4b79",
    name: "Matteo",
  },
  {
    id: "d55487b1-c266-48b0-83ba-b8dc43f3c012",
    name: "Alessandro",
  },
]

const DEMO_GAMES: NewGameAction[] = [
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.USA,
    modules: [{ id: "id-garage-module-id", type: GarageModuleType.Starting }],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 1,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 2,
        colorId: "blue",
      },
    ],
    startedOn: new Date("2023-06-23T18:00:00.000+0200").getTime(),
  },
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.Italia,
    modules: [{ id: "id-garage-module-id", type: GarageModuleType.Starting }],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 2,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 1,
        colorId: "blue",
      },
    ],
    startedOn: new Date("2023-06-25T18:00:00.000+0200").getTime(),
  },
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.France,
    modules: [{ id: "id-garage-module-id", type: GarageModuleType.Starting }],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 1,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 2,
        colorId: "blue",
      },
    ],
    startedOn: new Date("2023-06-27T18:00:00.000+0200").getTime(),
  },
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.GreatBritain,
    modules: [{ id: "id-garage-module-id", type: GarageModuleType.Starting }],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 1,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 2,
        colorId: "blue",
      },
    ],
    startedOn: new Date("2023-06-29T18:00:00.000+0200").getTime(),
  },
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.USA,
    modules: [
      { id: "id-weather-module-id" },
      { id: "id-garage-module-id", type: GarageModuleType.Starting },
    ],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 1,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 2,
        colorId: "blue",
      },
    ],
    startedOn: new Date("2023-07-01T18:00:00.000+0200").getTime(),
  },
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.Italia,
    modules: [
      { id: "id-weather-module-id" },
      { id: "id-garage-module-id", type: GarageModuleType.Starting },
    ],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 2,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 1,
        colorId: "blue",
      },
    ],
    startedOn: new Date("2023-07-03T18:00:00.000+0200").getTime(),
  },
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.France,
    modules: [
      { id: "id-weather-module-id" },
      { id: "id-garage-module-id", type: GarageModuleType.Starting },
    ],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 1,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 2,
        colorId: "blue",
      },
    ],
    startedOn: new Date("2023-07-04T14:00:00.000+0200").getTime(),
  },
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.GreatBritain,
    modules: [
      { id: "id-weather-module-id" },
      { id: "id-garage-module-id", type: GarageModuleType.Starting },
    ],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 1,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 2,
        colorId: "blue",
      },
    ],
    startedOn: new Date("2023-07-04T18:00:00.000+0200").getTime(),
  },
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.USA,
    modules: [
      { id: "id-weather-module-id" },
      { id: "id-garage-module-id", type: GarageModuleType.Regular },
    ],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 1,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 2,
        colorId: "blue",
      },
    ],
    startedOn: new Date("2023-07-05T18:00:00.000+0200").getTime(),
  },
  {
    creator: "d55487b1-c266-48b0-83ba-b8dc43f3c012",
    status: GameStatus.Finished,
    track: GameTrack.USA,
    modules: [{ id: "id-garage-module-id", type: GarageModuleType.Starting }],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 3,
        colorId: "grey",
      },
      {
        id: "9e3e1f4c-6571-437c-8b92-2ef102846076",
        finishOrder: 2,
        colorId: "blue",
      },
      {
        id: "41debc9f-4d74-4011-a591-4ba355ba4b79",
        finishOrder: 4,
        colorId: "red",
      },
      {
        id: "d55487b1-c266-48b0-83ba-b8dc43f3c012",
        finishOrder: 1,
        colorId: "yellow",
      },
    ],
    startedOn: new Date("2023-07-06T19:00:00.000+0200").getTime(),
  },
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.Italia,
    modules: [
      { id: "id-weather-module-id" },
      { id: "id-garage-module-id", type: GarageModuleType.Regular },
    ],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 2,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 1,
        colorId: "blue",
      },
    ],
    startedOn: new Date("2023-07-10T18:00:00.000+0200").getTime(),
  },
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.France,
    modules: [
      { id: "id-weather-module-id" },
      { id: "id-garage-module-id", type: GarageModuleType.Regular },
    ],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 1,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 2,
        colorId: "blue",
      },
    ],
    startedOn: new Date("2023-07-14T18:00:00.000+0200").getTime(),
  },
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.GreatBritain,
    modules: [
      { id: "id-weather-module-id" },
      { id: "id-garage-module-id", type: GarageModuleType.Regular },
    ],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 1,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 2,
        colorId: "blue",
      },
    ],
    startedOn: new Date("2023-07-17T18:00:00.000+0200").getTime(),
  },
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.USA,
    modules: [
      { id: "id-weather-module-id" },
      { id: "id-garage-module-id", type: GarageModuleType.Advanced },
    ],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 2,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 1,
        colorId: "blue",
      },
    ],
    startedOn: new Date("2023-07-21T18:00:00.000+0200").getTime(),
  },
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.Italia,
    modules: [
      { id: "id-weather-module-id" },
      { id: "id-garage-module-id", type: GarageModuleType.Advanced },
    ],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 1,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 2,
        colorId: "blue",
      },
    ],
    startedOn: new Date("2023-07-27T18:00:00.000+0200").getTime(),
  },
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.France,
    modules: [
      { id: "id-weather-module-id" },
      { id: "id-garage-module-id", type: GarageModuleType.Advanced },
    ],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 1,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 2,
        colorId: "green",
      },
    ],
    startedOn: new Date("2023-08-02T18:40:20.000+0200").getTime(),
  },
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.GreatBritain,
    modules: [
      { id: "id-weather-module-id" },
      { id: "id-garage-module-id", type: GarageModuleType.Advanced },
    ],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 2,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 1,
        colorId: "blue",
      },
    ],
    startedOn: new Date("2023-08-04T18:00:00.000+0200").getTime(),
  },
  {
    creator: "c1094a42-3d87-412a-8b0e-6b636b10b215",
    status: GameStatus.Finished,
    track: GameTrack.France,
    modules: [
      { id: "id-weather-module-id" },
      { id: "id-garage-module-id", type: GarageModuleType.Advanced },
    ],
    players: [
      {
        id: "c1094a42-3d87-412a-8b0e-6b636b10b215",
        finishOrder: 2,
        colorId: "grey",
      },
      {
        id: "38746af4-d425-499e-988c-8092251cb76f",
        finishOrder: 1,
        colorId: "blue",
      },
    ],
    startedOn: new Date("2023-08-05T18:00:00.000+0200").getTime(),
  },
]

interface DemoDataButtonProps extends ComponentPropsWithoutRef<typeof Button> {
  children: ReactNode
}

export const DemoDataButton = ({ children, ...rest }: DemoDataButtonProps) => {
  const dispatch = useAppDispatch()
  const players = useAppSelector(selectPlayers)
  const handleClick = () => {
    const existingPlayerIdMap = new Map<string, string>()
    DEMO_PLAYERS.filter((demoPlayer) => {
      demoPlayer.id = demoPlayer.id ?? uuid()
      const existingPlayer = players.find((p) => p.name === demoPlayer.name)
      if (existingPlayer) {
        existingPlayerIdMap.set(demoPlayer.id, existingPlayer.id)
        return false
      }
      return true
    }).forEach((demoPlayer) => {
      console.log("Adding demo player", demoPlayer)
      dispatch(addPlayerAsync(demoPlayer))
    })
    console.log("Replacing demo user ids", existingPlayerIdMap)
    DEMO_GAMES.forEach((demoGame) => {
      demoGame.players.forEach((demoPlayer) => {
        const mappedId = existingPlayerIdMap.get(demoPlayer.id)
        if (mappedId) {
          demoPlayer.id = mappedId
        }
      })
      demoGame.creator =
        existingPlayerIdMap.get(demoGame.creator) ?? demoGame.creator
      console.log("Adding demo game", demoGame)
      dispatch(addGameAsync(demoGame))
      dispatch(setAlert({ severity: "info", text: "Added demo data" }))
    })
  }
  return (
    <Button onClick={handleClick} {...rest}>
      {children}
    </Button>
  )
}
