import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { compress, decompress } from "./compressionUtils"
import { RootState } from "../../app/store"
import {
  selectMustWelcome,
  selectUserPlayerId,
} from "../common/userSettingsSlice"
import { Game } from "../games/types"
import { selectGames } from "../games/gamesSlice"
import { selectPlayersByIds } from "../players/playersSlice"
import { Player } from "../players/types"
import {
  isSharePlayerBootstrapAction,
  ShareGameAction,
  SharePlayerAction,
  SharePlayerBootstrapAction,
  SharingBaseAction,
} from "./types"

const MAX_QRCODE_SIZE = 2953
const MAX_URL_SIZE = 8192
const MAX_PAYLOAD_SIZE = Math.min(MAX_URL_SIZE, MAX_QRCODE_SIZE)

const URL_PAYLOAD_IDENTIFIER = "d"
const URL_PAYLOAD_PREFIX = `${window.location.origin}/?${URL_PAYLOAD_IDENTIFIER}=`
const MAX_URL_PAYLOAD_SIZE = MAX_PAYLOAD_SIZE - URL_PAYLOAD_PREFIX.length

interface ShareState {
  receiveModalOpen: boolean
  shareModalOpen: boolean
  receivedAction?: SharingBaseAction
  shareUrl?: string
  suggestedPlayer?: Player
}

const initialState: ShareState = {
  receiveModalOpen: false,
  shareModalOpen: false,
}

export const receiveDataAsync = createAsyncThunk(
  "share/receiveDataAsync",
  async (hexPayload: string, { getState }) => {
    console.log("receiveDataAsync received", hexPayload)
    const strPayload = await decompress(hexPayload)
    const shareAction = JSON.parse(strPayload)

    if (isSharePlayerBootstrapAction(shareAction)) {
      const mustWelcome = selectMustWelcome(getState() as RootState)
      if (!mustWelcome) {
        throw new Error("Already setup, no use for SharePlayerBootstrapAction")
      }
    }

    return shareAction
  },
)

export const sharePlayerAsync = createAsyncThunk(
  "share/sharePlayerAsync",
  async (player: Player, { getState }) => {
    const userPlayerId = selectUserPlayerId(getState() as RootState)
    if (!userPlayerId) {
      throw new Error("Can't share player, user doesn't have a player id")
    }
    const payload: SharePlayerAction = {
      action: "SHARE_PLAYER",
      sender: userPlayerId,
      player: player,
    }

    const strPayload = JSON.stringify(payload)
    const hexPayload = await compress(strPayload)
    if (hexPayload.length > MAX_URL_PAYLOAD_SIZE) {
      throw new Error(
        `Can't share player, hexPayload too big: hexPayload(${hexPayload.length}) > MAX_URL_PAYLOAD_SIZE(${MAX_URL_PAYLOAD_SIZE})`,
      )
    }
    return hexPayload
  },
)

export const shareGameAsync = createAsyncThunk(
  "share/shareGameAsync",
  async (game: Game, { getState }) => {
    const userPlayerId = selectUserPlayerId(getState() as RootState)
    if (!userPlayerId) {
      throw new Error("Can't share player, user doesn't have a player id")
    }

    const gamePlayers = selectPlayersByIds(
      getState() as RootState,
      game.players.map((p) => p.id),
    )

    const payload: ShareGameAction = {
      action: "SHARE_GAME",
      sender: userPlayerId,
      game,
      players: gamePlayers,
    }

    const strPayload = JSON.stringify(payload)
    const hexPayload = await compress(strPayload)
    if (hexPayload.length > MAX_URL_PAYLOAD_SIZE) {
      throw new Error(
        `Can't share game, hexPayload too big: hexPayload(${hexPayload.length}) > MAX_URL_PAYLOAD_SIZE(${MAX_URL_PAYLOAD_SIZE})`,
      )
    }
    return hexPayload
  },
)

export const sharePlayerBootstrapAsync = createAsyncThunk(
  "share/sharePlayerBootstrapAsync",
  async (player: Player, { getState }) => {
    const senderPlayerId = selectUserPlayerId(getState() as RootState)
    if (!senderPlayerId) {
      throw new Error("Can't share player, user doesn't have a player id")
    }

    const playerGames = selectGames(getState() as RootState).filter((g) =>
      g.players.map((p) => p.id).includes(player.id),
    )
    const gamePlayersIds = playerGames
      .flatMap((g) => g.players.map((p) => p.id))
      .filter((id, idx, playerIds) => playerIds.indexOf(id) === idx)
    const players = selectPlayersByIds(getState() as RootState, [
      ...gamePlayersIds,
      senderPlayerId,
    ])

    const payload: SharePlayerBootstrapAction = {
      action: "SHARE_PLAYER_BOOTSTRAP",
      sender: senderPlayerId,
      games: playerGames,
      playerId: player.id,
      players,
    }

    const strPayload = JSON.stringify(payload)
    const hexPayload = await compress(strPayload)
    if (hexPayload.length > MAX_URL_PAYLOAD_SIZE) {
      throw new Error(
        `Can't bootstrap player, hexPayload too big: hexPayload(${hexPayload.length}) > MAX_URL_PAYLOAD_SIZE(${MAX_URL_PAYLOAD_SIZE})`,
      )
    }
    return hexPayload
  },
)

export const sharingSlice = createSlice({
  name: "share",
  initialState,
  reducers: {
    closeReceiveModal: (state) => {
      state.receiveModalOpen = false
    },
    closeShareModal: (state) => {
      state.shareModalOpen = false
    },
    clearPlayerBootstrap: (state) => {
      state.suggestedPlayer = undefined
      state.receivedAction = undefined
      state.receiveModalOpen = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(receiveDataAsync.fulfilled, (state, action) => {
        const shareAction = action.payload
        console.log("receiveDataAsync.fulfilled", shareAction)
        state.receiveModalOpen = true
        state.receivedAction = shareAction
        if (isSharePlayerBootstrapAction(shareAction)) {
          const player = shareAction.players.find(
            (p) => p.id === shareAction.playerId,
          )
          if (!player) {
            throw new Error(
              "Inconstistent player bootstrap data, player detail missing",
            )
          }
          state.suggestedPlayer = player
        }
      })
      .addCase(receiveDataAsync.rejected, (state, action) => {
        console.log("receiveDataAsync.rejected", action.error)
      })

      .addCase(shareGameAsync.fulfilled, (state, action) => {
        console.log("shareGameAsync.fulfilled", action.payload)
        state.shareModalOpen = true
        state.shareUrl = URL_PAYLOAD_PREFIX + action.payload
      })
      .addCase(shareGameAsync.rejected, (state, action) => {
        console.log("shareGameAsync.rejected", action.error)
      })

      .addCase(sharePlayerAsync.fulfilled, (state, action) => {
        console.log("sharePlayerAsync.fulfilled", action.payload)
        state.shareModalOpen = true
        state.shareUrl = URL_PAYLOAD_PREFIX + action.payload
      })
      .addCase(sharePlayerAsync.rejected, (state, action) => {
        console.log("sharePlayerAsync.rejected", action.error)
      })

      .addCase(sharePlayerBootstrapAsync.fulfilled, (state, action) => {
        console.log("sharePlayerBootstrapAsync.fulfilled", action.payload)
        state.shareModalOpen = true
        state.shareUrl = URL_PAYLOAD_PREFIX + action.payload
      })
      .addCase(sharePlayerBootstrapAsync.rejected, (state, action) => {
        console.log("sharePlayerBootstrapAsync.rejected", action.error)
      })
  },
})

export const { closeReceiveModal, closeShareModal, clearPlayerBootstrap } =
  sharingSlice.actions

export const selectReceiveModalOpen = (state: RootState) =>
  state.sharing.receiveModalOpen
export const selectShareModalOpen = (state: RootState) =>
  state.sharing.shareModalOpen
export const selectShareReceivedAction = (state: RootState) =>
  state.sharing.receivedAction
export const selectShareUrl = (state: RootState) => state.sharing.shareUrl
export const selectSuggestedPlayer = (state: RootState) =>
  state.sharing.suggestedPlayer

export default sharingSlice.reducer
