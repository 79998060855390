import { useState } from "react"
import { useTranslation } from "react-i18next"
import { v4 as uuid } from "uuid"

import { Button, Grid, TextField } from "@mui/material"

import { useAppSelector } from "../../app/hooks"
import { selectSuggestedPlayer } from "../sharing/sharingSlice"
import { Player, PlayerType } from "./types"

import { PaperButtons } from "../common/Paper"
import { PlayerColorSelect } from "./PlayerColorSelect"

interface PlayerFormProps {
  onSubmit: (player: Player) => void
}

export const PlayerForm = (props: PlayerFormProps) => {
  const { t } = useTranslation()
  const suggestedPlayer = useAppSelector(selectSuggestedPlayer)
  const playerInitialValues: Player = {
    id: suggestedPlayer?.id ?? uuid(),
    version: (suggestedPlayer?.version ?? 0) + 1,
    name: suggestedPlayer?.name ?? "",
    type: PlayerType.Profile,
  }
  const initialValidationErrors = {
    name: false,
  }
  const [player, setPlayer] = useState(playerInitialValues)
  const [validationErrors, setValidationErrors] = useState(
    initialValidationErrors,
  )

  const handleSubmit = () => {
    setValidationErrors(initialValidationErrors)
    if (player.name) {
      props.onSubmit(player)
    } else {
      setValidationErrors((prevState) => ({ ...prevState, name: true }))
    }
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            error={validationErrors.name}
            helperText={
              validationErrors.name && t("players.name_validation_error")
            }
            label={t("players.name")}
            aria-label={t("players.name")}
            fullWidth
            variant="standard"
            value={player.name}
            onChange={(e) => {
              setPlayer((prevState) => ({
                ...prevState,
                name: e.target.value,
              }))
            }}
          />
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={12} md={6}>
            <PlayerColorSelect
              label={t("players.preferred_color")}
              selectedColorId={player.preferredColorId}
              onChange={(colorId) => {
                setPlayer((prevState) => ({
                  ...prevState,
                  preferredColorId: colorId,
                }))
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <PaperButtons
        buttons={
          <Button variant="contained" onClick={handleSubmit}>
            {t("start")}
          </Button>
        }
      />
    </>
  )
}
