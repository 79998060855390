export const hexToUint8array = (
  hex_str: string,
): Uint8Array => {
  let count = 0,
    hex_arr,
    hex_data = [],
    hex_len,
    i

  if (hex_str.trim() === "") {
    return new Uint8Array([])
  }

  /// Check for invalid hex characters.
  if (/[^0-9a-fA-F\s]/.test(hex_str)) {
    throw new Error("Invalid hex data")
  }

  hex_arr = hex_str.split(/(..)/g)
  hex_len = hex_arr.length

  let result = new Uint8Array([])
  for (i = 0; i < hex_len; ++i) {
    if (hex_arr[i].trim() === "") {
      continue
    }
    const value = parseInt(hex_arr[i], 16)
    result = new Uint8Array([...result, value])
    hex_data[count++] = value
  }

  return result
}

export const hexToNumberArray = (hex_str: string): number[] => {
  let count = 0,
    hex_arr,
    hex_data = [],
    hex_len,
    i

  if (hex_str.trim() === "") {
    return []
  }

  /// Check for invalid hex characters.
  if (/[^0-9a-fA-F\s]/.test(hex_str)) {
    throw new Error("Invalid hex data")
  }

  hex_arr = hex_str.split(/(..)/g)
  hex_len = hex_arr.length

  for (i = 0; i < hex_len; ++i) {
    if (hex_arr[i].trim() === "") {
      continue
    }
    hex_data[count++] = parseInt(hex_arr[i], 16)
  }

  return hex_data
}

export const arrayToHex = (
  byte_arr: number[] | Uint8Array,
): string => {
  let hex_str = "",
    i,
    len,
    tmp_hex

  len = byte_arr.length

  for (i = 0; i < len; ++i) {
    if (byte_arr[i] < 0) {
      byte_arr[i] = byte_arr[i] + 256
    }
    tmp_hex = byte_arr[i].toString(16)

    /// Add leading zero.
    if (tmp_hex.length === 1) {
      tmp_hex = "0" + tmp_hex
    }

    hex_str += tmp_hex
  }

  return hex_str.trim()
}
