import { Avatar } from "@mui/material"

import { PlayerColor } from "./types"

export const PlayerColorAvatar = (props: { color: PlayerColor }) => {
  return (
    <Avatar
      sx={{ bgcolor: props.color.color }}
      alt={`${props.color.number} ${props.color.color}`}
    >
      {props.color.number}
    </Avatar>
  )
}
