export enum GameStatus {
  Starting = "STARTING",
  Started = "STARTED",
  Finished = "FINISHED",
}

export enum GameTrack {
  USA = "USA",
  Italia = "Italia",
  GreatBritain = "Great Britain",
  France = "France",
}

export enum GarageModuleType {
  Starting = "Starting",
  Regular = "Regular",
  Advanced = "Advanced",
}

export interface GarageModule {
  id: "id-garage-module-id"
  type: GarageModuleType
}

export interface WeatherModule {
  id: "id-weather-module-id"
}

export interface LegendsModule {
  id: "id-legends-module-id"
}

export type GameModule = WeatherModule | GarageModule | LegendsModule

export interface GamePlayer {
  id: string
  finishOrder?: number
  colorId?: string
}

export interface Game {
  id: string
  creator: string
  status: GameStatus
  track: GameTrack
  modules: GameModule[]
  players: GamePlayer[]
  createdOn: number
  startedOn?: number
  endedOn?: number
}
