export interface PlayerColor {
  id: string
  number: number
  color: string
}

export const PLAYER_COLORS: { [id: string]: PlayerColor } = {
  black: {
    id: "black",
    number: 7,
    color: "#000000",
  },
  green: {
    id: "green",
    number: 5,
    color: "#009541",
  },
  blue: {
    id: "blue",
    number: 10,
    color: "#005AA6",
  },
  red: {
    id: "red",
    number: 3,
    color: "#DD0E22",
  },
  grey: {
    id: "grey",
    number: 2,
    color: "#868E90",
  },
  yellow: {
    id: "yellow",
    number: 14,
    color: "#FCE502",
  },
}

export enum PlayerType {
  Local = "LOCAL",
  Profile = "PROFILE",
}

export interface Player {
  id: string
  version: number
  name: string
  type: PlayerType
  preferredColorId?: string
}
