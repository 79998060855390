import { Checkbox, List } from "@mui/material"

import { useAppSelector } from "../../app/hooks"
import { selectPlayers } from "./playersSlice"

import { PlayerListItem } from "./PlayerListItem"

interface PlayersSelectProps {
  selectedIds: string[]
  onChange: (id: string) => void
}

export const PlayersSelect = (props: PlayersSelectProps) => {
  const players = useAppSelector(selectPlayers)

  return (
    <List>
      {players.map((player) => {
        const labelId = `checkbox-list-secondary-label-${player.id}`
        return (
          <PlayerListItem
            key={player.id}
            playerId={player.id}
            labelId={labelId}
            onClick={() => props.onChange(player.id)}
            secondaryAction={
              <Checkbox
                edge="end"
                onChange={() => props.onChange(player.id)}
                checked={props.selectedIds.indexOf(player.id) !== -1}
                inputProps={{ "aria-labelledby": labelId }}
              />
            }
          />
        )
      })}
    </List>
  )
}
