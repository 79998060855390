import { useTranslation } from "react-i18next"

import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material"

export const PlayerFinishOrderSelect = (props: {
  availableFinishOrders?: number[]
  selectedFinishOrder?: number
  onChange: (finishOrder: number) => void
}) => {
  const { t } = useTranslation()
  const values = props.availableFinishOrders?.slice() ?? [1, 2, 3, 4, 5, 6]
  if (
    props.selectedFinishOrder &&
    !values.includes(props.selectedFinishOrder)
  ) {
    values.push(props.selectedFinishOrder)
    values.sort()
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">
        {t("games.finishOrder")}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={t("games.finishOrder")}
        value={props.selectedFinishOrder ?? 0}
        onChange={(event) => {
          props.onChange(+event.target.value)
        }}
      >
        <MenuItem value={0}></MenuItem>
        {values.map((value) => (
          <MenuItem key={value} value={value}>
            <ListItemText primary={value} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
