import { Avatar } from "@mui/material"

import { useAppSelector } from "../../app/hooks"
import { PLAYER_COLORS } from "./types"
import { selectPlayerById } from "./playersSlice"

interface PlayerAvatarProps {
  playerId: string
  gameColorId?: string
}

export const PlayerAvatar = (props: PlayerAvatarProps) => {
  const player = useAppSelector((state) =>
    selectPlayerById(state, props.playerId),
  )
  if (!player) {
    throw new Error("Passed non existent player to PlayerAvatar")
  }

  let color
  if (props.gameColorId) {
    color = PLAYER_COLORS[props.gameColorId]
  } else if (player.preferredColorId) {
    color = PLAYER_COLORS[player.preferredColorId]
  } else {
    return <Avatar>{player.name[0]}</Avatar>
  }

  return (
    <Avatar sx={{ bgcolor: color.color }} alt={`${color.number} ${color.id}`}>
      {color.number}
    </Avatar>
  )
}
