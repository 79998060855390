import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  receiveDataAsync,
  selectReceiveModalOpen,
  selectShareModalOpen,
} from "./sharingSlice"

import { ShareModal } from "./ShareModal"
import { ReceiveModal } from "./ReceiveModal"

export const SharingContext = (props: { paused: boolean }) => {
  const dispatch = useAppDispatch()
  const receiveModalOpen = useAppSelector(selectReceiveModalOpen)
  const shareModalOpen = useAppSelector(selectShareModalOpen)
  const [searchParams, setSearchParams] = useSearchParams()
  const urlPayload = searchParams.get("d")

  useEffect(() => {
    if (urlPayload) {
      dispatch(receiveDataAsync(urlPayload))
    }
  }, [dispatch, urlPayload])

  return (
    <>
      <ReceiveModal modalOpen={!props.paused && receiveModalOpen} />
      <ShareModal modalOpen={!props.paused && shareModalOpen} />
    </>
  )
}
