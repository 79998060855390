import { Game } from "../games/types"
import { Player } from "../players/types"

export interface SharingBaseAction {
  action: string
  sender: string
}

export interface SharePlayerAction extends SharingBaseAction {
  action: "SHARE_PLAYER"
  player: Player
}

export interface ShareGameAction extends SharingBaseAction {
  action: "SHARE_GAME"
  game: Game
  players: Player[]
}

export interface SharePlayerBootstrapAction extends SharingBaseAction {
  action: "SHARE_PLAYER_BOOTSTRAP"
  games: Game[]
  playerId: string
  players: Player[]
}

export const isSharePlayerBootstrapAction = (
  obj: any,
): obj is SharePlayerBootstrapAction => {
  return (
    obj.action === "SHARE_PLAYER_BOOTSTRAP" && typeof obj.playerId === "string"
  )
}
