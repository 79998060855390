import { useEffect } from "react"
import { Outlet, ScrollRestoration } from "react-router-dom"

import { Alert, Container, Snackbar } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { loadGamesAsync, selectGamesStatus } from "../games/gamesSlice"
import { loadPlayersAsync, selectPlayersStatus } from "../players/playersSlice"
import { clearAlert, selectAlert, selectMustWelcome } from "./userSettingsSlice"

import { TopBar } from "./TopBar"
import { Footer } from "./Footer"
import { WelcomeLayout } from "../welcome/WelcomeLayout"
import { SharingContext } from "../sharing/SharingContext"

export const MainLayout = () => {
  const dispatch = useAppDispatch()
  const mustWelcome = useAppSelector(selectMustWelcome)
  const alert = useAppSelector(selectAlert)
  const gamesSliceStatus = useAppSelector(selectGamesStatus)
  const playersSliceStatus = useAppSelector(selectPlayersStatus)

  useEffect(() => {
    if (playersSliceStatus !== "loaded") {
      dispatch(loadPlayersAsync())
    }
  }, [dispatch, playersSliceStatus])

  useEffect(() => {
    if (gamesSliceStatus !== "loaded") {
      dispatch(loadGamesAsync())
    }
  }, [dispatch, gamesSliceStatus])

  const handleClose = () => {
    dispatch(clearAlert())
  }

  const content = mustWelcome ? (
    <WelcomeLayout />
  ) : (
    <>
      <TopBar />
      <Outlet />
    </>
  )

  return (
    <Container disableGutters maxWidth="lg">
      <ScrollRestoration />
      <CssBaseline />
      {content}
      <SharingContext paused={mustWelcome} />
      <Footer />
      <Snackbar open={!!alert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert?.severity}>
          {alert?.text}
        </Alert>
      </Snackbar>
    </Container>
  )
}
