import { useAppSelector } from "../../app/hooks"
import { selectWelcomeStep } from "../common/userSettingsSlice"

import { WelcomePaper } from "./WelcomePaper"
import { SetupPlayer } from "./SetupPlayer"
import { ReactNode } from "react"
import { Navigate } from "react-router-dom"

export const WelcomeLayout = () => {
  const welcomeStep = useAppSelector(selectWelcomeStep)

  const currentStep = (): ReactNode => {
    switch (welcomeStep) {
      case "start":
        return <WelcomePaper />
      case "player":
        return <SetupPlayer />
    }
  }

  return (
    <>
      <Navigate to="/" />
      {currentStep()}
    </>
  )
}
