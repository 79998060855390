import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit"
import { v4 as uuid } from "uuid"

import { RootState } from "../../app/store"
import { addPlayer, getAllPlayers } from "./db/PlayersStoreApi"
import { Player, PlayerType } from "./types"

export interface PlayersState {
  status: "loading" | "loaded"
  playersAdapterState: EntityState<Player>
}

export interface NewPlayerAction {
  id?: string
  name: string
  version?: number
  type?: PlayerType
  preferredColorId?: string
}

export const addPlayerAsync = createAsyncThunk(
  "players/addPlayerAsync",
  async (newPlayerAction: NewPlayerAction, { getState }) => {
    const newPlayerId = newPlayerAction.id ?? uuid()
    const state = getState() as RootState
    if (newPlayerAction.id && selectPlayerById(state, newPlayerId)) {
      throw new Error("Adding duplicate player, aborting")
    }
    const newPlayer: Player = {
      id: newPlayerId,
      version: newPlayerAction.version ?? 1,
      name: newPlayerAction.name,
      type: newPlayerAction.type ?? PlayerType.Local,
      preferredColorId: newPlayerAction.preferredColorId,
    }

    try {
      await addPlayer(newPlayer)
      return newPlayer
    } catch (error) {
      console.log("addPlayerAsync failure", error)
      throw error
    }
  },
)
export const loadPlayersAsync = createAsyncThunk(
  "players/loadPlayersAsync",
  async () => {
    try {
      return await getAllPlayers()
    } catch (error) {
      console.log("loadPlayersAsync failure", error)
      throw error
    }
  },
)

const playersAdapter = createEntityAdapter<Player>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

export const playersSlice = createSlice({
  name: "players",
  initialState: (): PlayersState => ({
    status: "loading",
    playersAdapterState: playersAdapter.getInitialState(),
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadPlayersAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadPlayersAsync.fulfilled, (state, action) => {
        state.status = "loaded"
        const players = action.payload
        console.log("getAllPlayersAsync.fulfilled", players)
        playersAdapter.setAll(state.playersAdapterState, players)
      })
      .addCase(loadPlayersAsync.rejected, (state, action) => {
        console.log("getAllPlayersAsync.rejected", action.error)
      })
      .addCase(addPlayerAsync.fulfilled, (state, action) => {
        const player = action.payload
        console.log("AddPlayerAsync.fulfilled", player)
        playersAdapter.addOne(state.playersAdapterState, player)
      })
      .addCase(addPlayerAsync.rejected, (state, action) => {
        console.log("AddPlayerAsync.rejected", action.error)
      })
  },
})

const playersAdapterSelectors = playersAdapter.getSelectors<RootState>(
  (state) => state.players.playersAdapterState,
)

export const selectPlayersStatus = (state: RootState) => state.players.status
export const selectPlayers = (state: RootState) =>
  playersAdapterSelectors.selectAll(state)
export const selectPlayersCount = (state: RootState) =>
  playersAdapterSelectors.selectTotal(state)

export const selectPlayerById = (state: RootState, id: string) =>
  playersAdapterSelectors.selectById(state, id)

const selectIds = (state: RootState, ids: string[]) => ids
export const selectPlayersByIds = createSelector(
  [selectPlayers, selectIds],
  (players, ids) => {
    return players.filter((p) => ids.includes(p.id))
  },
)

export default playersSlice.reducer
