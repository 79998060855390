import { ReactNode } from "react"

import { Alert, AlertColor, AlertTitle } from "@mui/material"

export const AlertSection = (props: {
  title: string
  text: string
  severity: AlertColor
  action: ReactNode
}) => (
  <Alert severity={props.severity} action={props.action}>
    <AlertTitle>{props.title}</AlertTitle>
    {props.text}
  </Alert>
)
