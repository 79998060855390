import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "../../app/store"
import { AlertColor } from "@mui/material"

const LOCAL_STORAGE_KEY = "userSettings"

type WelcomeSteps = "start" | "player"

export interface AlertData {
  severity: AlertColor
  text: string
}

export interface UserSettingsState {
  userPlayerId?: string
  welcomeStep: WelcomeSteps
  alert?: AlertData
}

const initialState: UserSettingsState = {
  welcomeStep: "start",
}

const readInitialState = (
  baseInitialState: UserSettingsState,
): UserSettingsState => {
  const read = localStorage.getItem(LOCAL_STORAGE_KEY)

  if (!read) {
    console.log("No userSettingsState found in localstorage")
    return baseInitialState
  }

  const readState: UserSettingsState = JSON.parse(read)
  return { ...baseInitialState, ...readState }
}

export const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState: readInitialState(initialState),
  reducers: {
    clearSettings: (state) => {
      state.userPlayerId = undefined
      state.welcomeStep = initialState.welcomeStep
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify(state as UserSettingsState),
      )
    },
    setAlert: (state, action: PayloadAction<AlertData>) => {
      state.alert = action.payload
    },
    clearAlert: (state) => {
      state.alert = undefined
    },
    nextWelcomeStep: (state, action: PayloadAction<string | undefined>) => {
      switch (state.welcomeStep) {
        case "start":
          state.welcomeStep = "player"
          break
        case "player":
          if (!action.payload) {
            throw new Error("Must define a playerId in this step")
          }
          console.log("setting userPlayerId", action.payload)
          state.userPlayerId = action.payload
          state.welcomeStep = "player"
          break
      }

      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify(state as UserSettingsState),
      )
    },
  },
})

export const { clearSettings, setAlert, clearAlert, nextWelcomeStep } =
  userSettingsSlice.actions

export const selectUserPlayerId = (state: RootState): string | undefined =>
  state.userSettings.userPlayerId

export const selectMustWelcome = (state: RootState): boolean =>
  !state.userSettings.userPlayerId

export const selectWelcomeStep = (state: RootState): WelcomeSteps =>
  state.userSettings.welcomeStep

export const selectAlert = (state: RootState) => state.userSettings.alert

export default userSettingsSlice.reducer
