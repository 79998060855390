import { useTranslation } from "react-i18next"

import {
  Box,
  Button,
  ClickAwayListener,
  Link,
  Tooltip,
  Typography,
} from "@mui/material"
import { useState } from "react"

export const Footer = () => {
  const { t } = useTranslation()
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const handleTooltipOpen = () => {
    setTooltipOpen(true)
  }

  return (
    <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
      <Typography
        variant="subtitle1"
        align="center"
        color="text.secondary"
        component="p"
      >
        <Link
          target="_blank"
          rel="noopener"
          color="inherit"
          href="https://www.daysofwonder.com/heat/"
        >
          {t("heat_game.title")}
        </Link>
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        {t("heat_game.creator")}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        {"HeatTrack by "}
        <Link
          target="_blank"
          rel="noopener"
          color="inherit"
          href="https://gitlab.com/dbarchiesi/heattrack"
        >
          barchiesi
        </Link>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            onClose={handleTooltipClose}
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement="bottom"
            title={t("footer.built_date", { date: __BUILD_DATE__ })}
          >
            <Button variant="text" onClick={handleTooltipOpen}>
              - {__COMMIT_HASH__}
            </Button>
          </Tooltip>
        </ClickAwayListener>
      </Typography>
    </Box>
  )
}
