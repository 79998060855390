import { DbStore } from "../../../app/database"

export const PLAYERS_STORE_NAME = "players"

export const PlayersStore: DbStore = {
  name: PLAYERS_STORE_NAME,
  migrations: [
    {
      version: 1,
      migrate: (objectStore: IDBObjectStore) => {
        objectStore.createIndex("name", "name", { unique: false })
      },
    },
    {
      version: 2,
      migrate: (objectStore: IDBObjectStore) => {
        objectStore.createIndex("local", "local", { unique: false })
      },
    },
  ],
}
