import { ReactNode } from "react"

import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material"
import TimerIcon from "@mui/icons-material/Timer"
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents"
import PlayDisabledIcon from "@mui/icons-material/PlayDisabled"

import { useAppSelector } from "../../app/hooks"
import { Game, GameStatus } from "./types"
import { selectPlayersByIds } from "../players/playersSlice"
import { selectUserPlayerId } from "../common/userSettingsSlice"

interface GameListItemProps {
  game: Game
  onClick?: (id: string) => void
}

export const GameListItem = (props: GameListItemProps) => {
  const userPlayerId = useAppSelector(selectUserPlayerId)
  const gamePlayersIds = props.game.players.map((p) => p.id)
  const gamePlayers = useAppSelector((state) =>
    selectPlayersByIds(state, gamePlayersIds),
  )
  const gameDate = new Date(props.game.startedOn ?? props.game.createdOn)
  let userWon = false
  if (props.game.status === GameStatus.Finished) {
    userWon =
      userPlayerId === props.game.players.find((p) => p.finishOrder === 1)?.id
  }

  const buttonWrapper = (
    gameId: string,
    children: ReactNode,
    onClick?: (id: string) => void,
  ): ReactNode => {
    if (onClick) {
      return (
        <ListItemButton onClick={() => onClick(gameId)}>
          {children}
        </ListItemButton>
      )
    } else {
      return children
    }
  }

  return (
    <ListItem alignItems="flex-start" disablePadding>
      {buttonWrapper(
        props.game.id,
        <>
          <ListItemAvatar>
            <GameAvatar status={props.game.status} won={userWon} />
          </ListItemAvatar>
          <ListItemText
            primary={`${gameDate.toLocaleDateString()}`}
            secondary={
              <>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {props.game.track}
                </Typography>
                {` — ${gamePlayers
                  .map((player) => player.name)
                  .join(", ")} - ${JSON.stringify(props.game.modules)}`}
              </>
            }
          />
        </>,
        props.onClick,
      )}
    </ListItem>
  )
}

const GameAvatar = (props: { status: GameStatus; won: boolean }) => {
  // FIXME Use mui color names or constants?
  const color = props.won ? "gold" : "white"

  // Starting
  let bgColor, icon
  if (props.status === GameStatus.Starting) {
    bgColor = "grey"
    icon = <PlayDisabledIcon />
  } else if (props.status === GameStatus.Started) {
    bgColor = "grey"
    icon = <TimerIcon />
  } else if (props.status === GameStatus.Finished) {
    bgColor = "primary.main"
    icon = <EmojiEventsIcon />
  }
  return <Avatar sx={{ bgcolor: bgColor, color }}>{icon}</Avatar>
}
