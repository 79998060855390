import { Player } from "../types"
import { connectDb } from "../../../app/database"
import { PLAYERS_STORE_NAME } from "./PlayersStore"

const playersStore = () =>
  connectDb().then((db) => {
    const transaction = db.transaction(PLAYERS_STORE_NAME, "readwrite")
    return {
      tx: transaction,
      store: transaction.objectStore(PLAYERS_STORE_NAME),
    }
  })

export const getAllPlayers = () =>
  new Promise<Player[]>((resolve, reject) => {
    playersStore().then(({ store }) => {
      const allPlayersRequest = store.getAll()
      allPlayersRequest.onsuccess = () => {
        const data: Player[] = allPlayersRequest.result
        resolve(data)
      }
      allPlayersRequest.onerror = (event) => {
        console.log("err", event)
        reject(event)
      }
    })
  })

export const addPlayer = (player: Player) =>
  new Promise<void>((resolve, reject) => {
    playersStore().then(({ tx, store }) => {
      const request = store.add(player)
      request.onerror = (event) => {
        console.log("addPlayer request.onerror", event)
        reject()
      }
      tx.onerror = (event) => {
        console.log("addPlayer tx.onerror", event)
        reject()
      }
      request.onsuccess = (event) => {
        console.log("addPlayer request.onsuccess", event)
      }
      tx.oncomplete = (event) => {
        console.log("addPlayer tx.oncomplete", event)
        resolve()
      }
    })
  })
