import { useTranslation } from "react-i18next"

import {
  FormControl,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material"

import { PLAYER_COLORS } from "./types"

import { PlayerColorAvatar } from "./PlayerColorAvatar"

export const PlayerColorSelect = (props: {
  label?: string
  availableColorIds?: string[]
  selectedColorId?: string
  onChange: (colorId: string) => void
}) => {
  const { t } = useTranslation()
  const label = props.label ?? t("players.color")

  const valueIds =
    props.availableColorIds?.slice() ?? Object.keys(PLAYER_COLORS)
  if (props.selectedColorId && !valueIds.includes(props.selectedColorId)) {
    valueIds.push(props.selectedColorId)
    valueIds.sort()
  }
  const values = valueIds.map((colorId) => PLAYER_COLORS[colorId])

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={label}
        value={props.selectedColorId ?? ""}
        onChange={(event) => {
          const colorId = event.target.value
          props.onChange(colorId)
        }}
      >
        <MenuItem value={0}></MenuItem>
        {values.map((color) => (
          <MenuItem key={color.id} value={color.id}>
            <ListItemAvatar>
              <PlayerColorAvatar color={color} />
            </ListItemAvatar>
            <ListItemText primary={`Color: ${color.color}`} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
