import { DbStore } from "../../../app/database"

export const GAMES_STORE_NAME = "games"

export const GamesStore: DbStore = {
  name: "games",
  migrations: [
    {
      version: 3,
      migrate: (objectStore: IDBObjectStore) => {
        objectStore.createIndex("status", "status", { unique: false })
        objectStore.createIndex("track", "track", { unique: false })
        objectStore.createIndex("modules", "modules", {
          unique: false,
          multiEntry: true,
        })
        objectStore.createIndex("players", "players", {
          unique: false,
          multiEntry: true,
        })
        objectStore.createIndex("finishOrder", "finishOrder", {
          unique: false,
          multiEntry: true,
        })
        objectStore.createIndex("createdOn", "createdOn", { unique: false })
      },
    },
  ],
}
