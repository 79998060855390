import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { Button, List, Stack, Typography } from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectPlayerById } from "./playersSlice"
import {
  sharePlayerAsync,
  sharePlayerBootstrapAsync,
} from "../sharing/sharingSlice"
import { PlayerType } from "./types"

import { Paper } from "../common/Paper"
import { PlayerListItem } from "./PlayerListItem"
import { AlertSection } from "../common/AlertSection"

export const PlayerDetails = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { playerId } = useParams()
  const player = useAppSelector((state) =>
    selectPlayerById(state, playerId ?? ""),
  )

  return (
    <Paper
      buttons={
        player &&
        player.type !== PlayerType.Local && (
          <Button
            onClick={() => {
              dispatch(sharePlayerAsync(player))
            }}
            variant="contained"
          >
            {t("sharing.share")}
          </Button>
        )
      }
    >
      <Typography variant="h4" gutterBottom>
        {t("players.title_details")}
      </Typography>
      <List>
        {player && <PlayerListItem key={player.id} playerId={player.id} />}
      </List>

      {player && player.type === PlayerType.Local && (
        <Stack spacing={2}>
          <AlertSection
            title={t("players.bootstrap_title")}
            text={t("players.bootstrap_description")}
            severity="info"
            action={
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  dispatch(sharePlayerBootstrapAsync(player))
                }}
              >
                {t("players.bootstrap")}
              </Button>
            }
          />
        </Stack>
      )}
    </Paper>
  )
}
