import { arrayToHex, hexToUint8array } from "./hexUtils"

const compress = (data: string) =>
  new Promise<string>(async (resolve) => {
    console.log(`compressionUtils compress length: ${data.length}`)
    console.log(
      `compressionUtils compress btoa(data) length: ${btoa(data).length}`,
    )

    const readableStream = new ReadableStream({
      start(controller) {
        const encoder = new TextEncoder()
        const inputUint8Array = encoder.encode(data)
        controller.enqueue(inputUint8Array)
        controller.close()
      },
    })

    const compressedReadableStream = readableStream.pipeThrough(
      new CompressionStream("gzip"),
    )

    const reader = compressedReadableStream.getReader()
    let compressResult = new Uint8Array()
    while (true) {
      const { done, value } = await reader.read()
      if (done) break
      let temp: Uint8Array = new Uint8Array(
        compressResult.length + value.length,
      )
      temp.set(compressResult)
      temp.set(value, compressResult.length)
      compressResult = temp
    }
    console.log(
      `compressionUtils compress finished length: ${compressResult.length}`,
    )

    const hexPayload = arrayToHex(compressResult)
    console.log(
      `compressionUtils compress hexPayload length: ${hexPayload.length}`,
      hexPayload,
    )
    resolve(hexPayload)
  })

const decompress = (data: string) =>
  new Promise<string>(async (resolve) => {
    const readableStream = new ReadableStream({
      start(controller) {
        controller.enqueue(hexToUint8array(data))
        controller.close()
      },
    })

    const decompressedReadableStream = readableStream.pipeThrough(
      new DecompressionStream("gzip"),
    )

    const deReader = decompressedReadableStream.getReader()
    let decompressResult = ""
    while (true) {
      const { done, value } = await deReader.read()
      if (done) break
      const asd = new TextDecoder().decode(value)
      decompressResult += asd
    }

    console.log(
      `compressionUtils decompress finished length: ${decompressResult.length}`,
    )
    resolve(decompressResult)
  })

export { compress, decompress }
