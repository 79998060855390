import { useState } from "react"
import { useTranslation } from "react-i18next"

import { Button, Grid, TextField, Typography } from "@mui/material"

import { useAppDispatch } from "../../app/hooks"
import { addPlayerAsync } from "./playersSlice"

import { PaperButtons } from "../common/Paper"

export const NewPlayer = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [newPlayerName, setNewPlayerName] = useState("")

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {t("players.add")}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            label={t("players.name")}
            aria-label={t("players.name_alt")}
            fullWidth
            variant="standard"
            value={newPlayerName}
            onChange={(e) => setNewPlayerName(e.target.value)}
          />
        </Grid>
      </Grid>

      <PaperButtons
        buttons={
          <>
            <Button
              variant="contained"
              onClick={() => {
                dispatch(addPlayerAsync({ name: newPlayerName }))
                setNewPlayerName("")
              }}
            >
              {t("add")}
            </Button>
            <Button onClick={() => setNewPlayerName("")}>{t("clear")}</Button>
          </>
        }
      />
    </>
  )
}
