import { ReactNode } from "react"

import {
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material"

import { useAppSelector } from "../../app/hooks"
import { selectPlayerById } from "./playersSlice"

import { PlayerAvatar } from "./PlayerAvatar"

type OnClickHandler = (id: string) => void

interface PlayerListItemProps {
  playerId: string
  onClick?: (id: string) => void
  labelId?: string
  secondaryAction?: ReactNode
  gameColorId?: string
}

export const PlayerListItem = (props: PlayerListItemProps) => {
  const player = useAppSelector((state) =>
    selectPlayerById(state, props.playerId),
  )
  if (!player) {
    return <ListItem />
  }

  let playerLabel = player.name

  const buttonWrapper = (
    playerId: string,
    children: ReactNode,
    onClick?: OnClickHandler,
  ): ReactNode => {
    if (onClick) {
      return (
        <ListItemButton onClick={() => onClick(playerId)}>
          {children}
        </ListItemButton>
      )
    } else {
      return children
    }
  }

  return (
    <ListItem
      secondaryAction={props.secondaryAction}
      disablePadding={!!props.onClick}
    >
      {buttonWrapper(
        player.id,
        <>
          <ListItemAvatar>
            <PlayerAvatar
              playerId={player.id}
              gameColorId={props.gameColorId}
            />
          </ListItemAvatar>
          <ListItemText
            id={props.labelId}
            primary={playerLabel}
            secondary={`#${player.id}`}
          />
        </>,
        props.onClick,
      )}
    </ListItem>
  )
}
