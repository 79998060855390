import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  Game,
  GameModule,
  GamePlayer,
  GameTrack,
  GarageModuleType,
} from "./types"
import { addGameAsync } from "./gamesSlice"
import { selectUserPlayerId } from "../common/userSettingsSlice"

import { Paper } from "../common/Paper"
import { PlayersSelect } from "../players/PlayersSelect"
import { GarageModuleSelect } from "./GarageModuleSelect"

export const NewGame = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userPlayerId = useAppSelector(selectUserPlayerId)
  const [selectedPlayersIds, setSelectedPlayersIds] = useState([] as string[])
  const [selectedTrack, setSelectedTrack] = useState("" as GameTrack)
  const [selectedGarageModuleType, setSelectedGarageModuleType] = useState(
    GarageModuleType.Starting,
  )
  const [weatherModuleEnabled, setWeatherModuleEnabled] = useState(false)
  const [legendsModuleEnabled, setLegendsModuleEnabled] = useState(false)
  const [legendsModuleUsable, setLegendsModuleUsable] = useState(true)

  if (!userPlayerId) {
    throw new Error("Can't create a game if user doesn't have a player profile")
  }

  const handlePlayerSelection = (id: string) => {
    const currentIndex = selectedPlayersIds.indexOf(id)
    const newChecked = [...selectedPlayersIds]

    if (currentIndex === -1) {
      newChecked.push(id)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setSelectedPlayersIds(newChecked)

    setLegendsModuleUsable(newChecked.length < 6)
  }

  const handleClear = () => {
    setSelectedPlayersIds([])
    setSelectedTrack("" as GameTrack)
    setSelectedGarageModuleType(GarageModuleType.Starting)
  }

  const handleAddGame = () => {
    const players = selectedPlayersIds.map(
      (id): GamePlayer => ({
        id,
      }),
    )
    const modules: GameModule[] = [
      {
        id: "id-garage-module-id",
        type: selectedGarageModuleType,
      },
    ]
    if (weatherModuleEnabled) {
      modules.push({
        id: "id-weather-module-id",
      })
    }
    if (legendsModuleUsable && legendsModuleEnabled) {
      modules.push({
        id: "id-legends-module-id",
      })
    }

    const newGame = {
      creator: userPlayerId,
      track: selectedTrack,
      modules,
      players,
    }
    dispatch(addGameAsync(newGame)).then((data) => {
      const game = data.payload as Game
      handleClear()
      navigate(`/games/${game.id}`)
    })
  }

  return (
    <Paper
      buttons={
        <>
          <Button
            variant="contained"
            disabled={!selectedTrack || selectedPlayersIds.length === 0}
            onClick={() => handleAddGame()}
          >
            {t("games.create")}
          </Button>
          <Button onClick={() => handleClear()}>{t("clear")}</Button>
        </>
      }
    >
      <Typography variant="h4" gutterBottom>
        {t("games.add")}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("games.track")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedTrack}
                label={t("games.track")}
                onChange={(event) =>
                  setSelectedTrack(event.target.value as GameTrack)
                }
              >
                <MenuItem value=""></MenuItem>
                {Object.values(GameTrack).map((track) => (
                  <MenuItem key={track} value={track}>
                    {track}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <GarageModuleSelect
              value={selectedGarageModuleType}
              onChange={setSelectedGarageModuleType}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={weatherModuleEnabled}
                    onChange={() =>
                      setWeatherModuleEnabled((prevState) => !prevState)
                    }
                  />
                }
                label={t("games.weather_module")}
              />
              <FormControlLabel
                disabled={!legendsModuleUsable}
                control={
                  <Checkbox
                    checked={legendsModuleEnabled}
                    onChange={() =>
                      setLegendsModuleEnabled((prevState) => !prevState)
                    }
                  />
                }
                label={t("games.legends_module")}
              />
            </FormGroup>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            {t("players.title")}
          </Typography>
          <PlayersSelect
            selectedIds={selectedPlayersIds}
            onChange={handlePlayerSelection}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
