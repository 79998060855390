import { useState } from "react"
import { useTranslation } from "react-i18next"

import { Button, ClickAwayListener, Tooltip, Typography } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"

import { useAppDispatch } from "../../app/hooks"
import { nextWelcomeStep } from "../common/userSettingsSlice"

import { Paper } from "../common/Paper"

export const WelcomePaper = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const handleTooltipOpen = () => {
    setTooltipOpen(true)
  }

  return (
    <Paper
      buttons={
        <>
          <Button
            variant="contained"
            onClick={() => {
              dispatch(nextWelcomeStep())
            }}
          >
            Next
          </Button>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              onClose={handleTooltipClose}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="bottom"
              title={t("welcome.description_tooltip")}
            >
              <Button onClick={handleTooltipOpen}>
                {" "}
                <InfoIcon />
              </Button>
            </Tooltip>
          </ClickAwayListener>
        </>
      }
    >
      <Typography variant="h4" gutterBottom>
        {t("welcome.title")}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("welcome.description_paragraph_1")}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("welcome.description_paragraph_2")}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("welcome.description_paragraph_3")}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("welcome.description_paragraph_4")}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("welcome.description_paragraph_5")}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("welcome.description_paragraph_6")}
      </Typography>
    </Paper>
  )
}
