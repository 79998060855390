import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { Grid, List, Typography } from "@mui/material"

import { useAppSelector } from "../../app/hooks"
import { selectPlayers } from "./playersSlice"

import { Paper } from "../common/Paper"
import { PlayerListItem } from "./PlayerListItem"
import { NewPlayer } from "./NewPlayer"

export const Players = () => (
  <Paper>
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <PlayersList />
      </Grid>
      <Grid item xs={12} md={6}>
        <NewPlayer />
      </Grid>
    </Grid>
  </Paper>
)

const PlayersList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const players = useAppSelector(selectPlayers)

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {t("players.title")}
      </Typography>
      <List>
        {players.map((player) => (
          <PlayerListItem
            key={player.id}
            playerId={player.id}
            onClick={() => {
              navigate(`/players/${player.id}`)
            }}
          />
        ))}
      </List>
    </>
  )
}
