import { useTranslation } from "react-i18next"

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material"

import { GarageModuleType } from "./types"

interface GarageModuleSelectProps {
  value: GarageModuleType
  onChange: (type: GarageModuleType) => void
}

export const GarageModuleSelect = (props: GarageModuleSelectProps) => {
  const { t } = useTranslation()

  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">
        {t("games.garage_module")}
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={props.value}
        onChange={(a) => props.onChange(a.target.value as GarageModuleType)}
      >
        {Object.values(GarageModuleType).map((value) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio />}
            label={value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
