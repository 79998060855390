import { useTranslation } from "react-i18next"

import { Button, List, Stack, Typography } from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { clearDb } from "../../app/database"
import { clearSettings, selectUserPlayerId } from "../common/userSettingsSlice"
import { loadPlayersAsync } from "../players/playersSlice"
import { loadGamesAsync } from "../games/gamesSlice"

import { Paper } from "../common/Paper"
import { DemoDataButton } from "./DemoDataButton"
import { PlayerListItem } from "../players/PlayerListItem"
import { AlertSection } from "../common/AlertSection"

export const Settings = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const userPlayerId = useAppSelector(selectUserPlayerId)

  const handleDataClear = () => {
    console.log("Settings starting data clear")
    clearDb()
      .then(() => {
        dispatch(clearSettings())
        dispatch(loadPlayersAsync())
        dispatch(loadGamesAsync())
      })
      .catch((error) => {
        console.log("Settings failed data clear", error)
      })
  }

  return (
    <Paper>
      <Typography variant="h4" gutterBottom>
        {t("settings.title")}
      </Typography>
      {userPlayerId && (
        <>
          <Typography variant="h5" gutterBottom>
            {t("players.player")}
          </Typography>
          <List>
            <PlayerListItem playerId={userPlayerId} />
          </List>
        </>
      )}

      <Typography variant="h5" gutterBottom>
        {t("settings.data")}
      </Typography>

      <Stack spacing={2}>
        <AlertSection
          title={t("settings.demo_title")}
          text={t("settings.demo_description")}
          severity="info"
          action={
            <DemoDataButton variant="contained">
              {t("settings.demo")}
            </DemoDataButton>
          }
        />
        <AlertSection
          title={t("settings.clear_title")}
          text={t("settings.clear_description")}
          severity="error"
          action={
            <Button variant="contained" color="error" onClick={handleDataClear}>
              {t("settings.clear")}
            </Button>
          }
        />
      </Stack>
    </Paper>
  )
}
