import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"

import playersReducer from "../features/players/playersSlice"
import gamesReducer from "../features/games/gamesSlice"
import sharingReducer from "../features/sharing/sharingSlice"
import userSettingsReducer from "../features/common/userSettingsSlice"

export const store = configureStore({
  reducer: {
    players: playersReducer,
    games: gamesReducer,
    sharing: sharingReducer,
    userSettings: userSettingsReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
