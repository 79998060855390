import { v4 as uuid } from "uuid"
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit"

import { RootState } from "../../app/store"
import {
  Game,
  GameModule,
  GamePlayer,
  GameStatus,
  GameTrack,
  GarageModuleType,
} from "./types"
import { addGame, getAllGames, updateGame } from "./db/GamesStoreApi"

export interface GamesState {
  status: "loading" | "loaded"
  gamesAdapterState: EntityState<Game>
}

export interface NewGameAction {
  creator: string
  status?: GameStatus
  track: GameTrack
  modules: GameModule[]
  players: GamePlayer[]
  createdOn?: number
  startedOn?: number
  endedOn?: number
}

export interface StartGameAction {
  id: string
  players: GamePlayer[]
  startedOn?: number
}

export interface FinishGameAction {
  id: string
  players: GamePlayer[]
  startedOn?: number
  endedOn?: number
}

const throwIfGameInvalid = (game: Game): void => {
  const gamePlayers = game.players.slice().sort((a, b) => {
    const aFinishOrder = a.finishOrder ?? 0
    const bFinishOrder = b.finishOrder ?? 0
    return aFinishOrder - bFinishOrder
  })
  if (!gamePlayers || !gamePlayers.length) {
    throw new Error("Can't add a game without players")
  }

  const hasFinishOrderSet = gamePlayers.every((p) => p.finishOrder)
  if (game.status === GameStatus.Started && hasFinishOrderSet) {
    throw new Error("Can't add a game with a finish order when just started")
  }
  if (game.status === GameStatus.Finished && !hasFinishOrderSet) {
    throw new Error("Can't add a finished game without a finish order")
  }

  if (hasFinishOrderSet) {
    for (let checkVal = 1; checkVal <= gamePlayers.length; ++checkVal) {
      if (gamePlayers[checkVal - 1].finishOrder !== checkVal) {
        throw new Error(
          "Can't add a finished game with a finish order having missing/extra players",
        )
      }
    }
  }

  const playerColors = new Set(gamePlayers.map((p) => p.colorId))
  if (
    game.status !== GameStatus.Starting &&
    playerColors.size !== gamePlayers.length
  ) {
    throw new Error(
      "Can't add a started/finished game without a unique color for each player",
    )
  }
}

export const addGameAsync = createAsyncThunk(
  "games/addGameAsync",
  async (newGameAction: NewGameAction) => {
    const newGameModules = newGameAction.modules
    let foundGarageModule = newGameModules.find(
      (m) => m.id === "id-garage-module-id",
    )
    if (!foundGarageModule) {
      newGameModules.push({
        id: "id-garage-module-id",
        type: GarageModuleType.Starting,
      })
    }

    const newGame: Game = {
      id: uuid(),
      creator: newGameAction.creator,
      status: newGameAction.status ?? GameStatus.Starting,
      track: newGameAction.track,
      modules: newGameModules,
      players: newGameAction.players,
      createdOn: newGameAction.createdOn ?? new Date().getTime(),
      startedOn: newGameAction.startedOn,
      endedOn: newGameAction.endedOn,
    }

    throwIfGameInvalid(newGame)

    try {
      await addGame(newGame)
      return newGame
    } catch (error) {
      console.log("addGameAsync failure", error)
      throw error
    }
  },
)

export const startGameAsync = createAsyncThunk(
  "games/startGameAsync",
  async (startGameAction: StartGameAction, { getState }) => {
    const prevGame = selectGameById(getState() as RootState, startGameAction.id)
    if (!prevGame) {
      throw new Error("Can't start non existant game")
    }
    if (prevGame.status !== GameStatus.Starting) {
      throw new Error("Can't start already started/finished game")
    }
    if (prevGame.players.length !== startGameAction.players.length) {
      throw new Error("Can't start game with different number of players")
    }
    const prevGamePlayerIds = prevGame.players.map((p) => p.id)
    const samePlayers = startGameAction.players.every((p) =>
      prevGamePlayerIds.includes(p.id),
    )
    if (!samePlayers) {
      throw new Error("Can't start game with different players")
    }
    const hasPlayerColors = startGameAction.players.every((p) => p.colorId)
    if (!hasPlayerColors) {
      throw new Error("Can't start game without player colors")
    }

    const updatedGame = {
      ...prevGame,
      status: GameStatus.Started,
      players: startGameAction.players,
      startedOn: startGameAction.startedOn,
    }

    throwIfGameInvalid(updatedGame)

    try {
      await updateGame(updatedGame)
      return updatedGame
    } catch (error) {
      console.log("startGameAsync failure", error)
      throw error
    }
  },
)

export const finishGameAsync = createAsyncThunk(
  "games/finishGameAsync",
  async (finishGameAction: FinishGameAction, { getState }) => {
    const prevGame = selectGameById(
      getState() as RootState,
      finishGameAction.id,
    )
    if (!prevGame) {
      throw new Error("Can't finish non existant game")
    }
    if (prevGame.status === GameStatus.Finished) {
      throw new Error("Can't finish already finished game")
    }

    const finishPlayers = finishGameAction.players.map((fP) => ({
      ...fP,
      colorId:
        fP.colorId ?? prevGame.players.find((p) => p.id === fP.id)?.colorId,
    }))

    if (prevGame.players.length !== finishPlayers.length) {
      throw new Error("Can't finish game with different number of players")
    }
    const prevGamePlayerIds = prevGame.players.map((p) => p.id)
    const samePlayers = finishPlayers.every((p) =>
      prevGamePlayerIds.includes(p.id),
    )
    if (!samePlayers) {
      throw new Error("Can't finish game with different players")
    }
    const hasFinishOrder = finishPlayers.every((p) => p.finishOrder)
    if (!hasFinishOrder) {
      throw new Error("Can't finish game without finish order")
    }

    const hasPlayersColors = finishPlayers.every((p) => p.colorId)
    if (!hasPlayersColors) {
      throw new Error("Can't finish game without player colors")
    }
    if (prevGame.status === GameStatus.Started) {
      prevGame.players.forEach((prevPlayer) => {
        const updatedPlayer = finishPlayers.find((p) => p.id === prevPlayer.id)
        if (prevPlayer.colorId !== updatedPlayer?.colorId) {
          throw new Error("Can't finish game changing player colors")
        }
      })
    }

    const updatedGame = {
      ...prevGame,
      status: GameStatus.Finished,
      players: finishPlayers.sort(
        (a, b) => (a.finishOrder ?? 0) - (b.finishOrder ?? 0),
      ),
      startedOn: finishGameAction.startedOn ?? prevGame.startedOn,
      endedOn: finishGameAction.endedOn,
    }

    throwIfGameInvalid(updatedGame)

    try {
      await updateGame(updatedGame)
      return updatedGame
    } catch (error) {
      console.log("finishGameAsync failure", error)
      throw error
    }
  },
)

export const loadGamesAsync = createAsyncThunk(
  "games/loadGamesAsync",
  async () => {
    try {
      return await getAllGames()
    } catch (error) {
      console.log("loadGamesAsync failure", error)
      throw error
    }
  },
)

const gamesAdapter = createEntityAdapter<Game>({
  sortComparer: (a, b) => {
    const aDate = a.startedOn ?? a.createdOn
    const bDate = b.startedOn ?? b.createdOn
    return bDate - aDate
  },
})

export const gamesSlice = createSlice({
  name: "games",
  initialState: (): GamesState => ({
    status: "loading",
    gamesAdapterState: gamesAdapter.getInitialState(),
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadGamesAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadGamesAsync.fulfilled, (state, action) => {
        state.status = "loaded"
        const games = action.payload
        console.log("getAllGamesAsync.fulfilled", games)
        gamesAdapter.setAll(state.gamesAdapterState, games)
      })
      .addCase(loadGamesAsync.rejected, (state, action) => {
        console.log("getAllGamesAsync.rejected", action.error)
      })

      .addCase(addGameAsync.fulfilled, (state, action) => {
        const game = action.payload
        console.log("addGameAsync.fulfilled", game)
        gamesAdapter.addOne(state.gamesAdapterState, game)
      })
      .addCase(addGameAsync.rejected, (state, action) => {
        console.log("addGameAsync.rejected", action.error)
      })

      .addCase(startGameAsync.fulfilled, (state, action) => {
        const game = action.payload
        console.log("startGameAsync.fulfilled", game)
        gamesAdapter.updateOne(state.gamesAdapterState, {
          id: game.id,
          changes: game,
        })
      })
      .addCase(startGameAsync.rejected, (state, action) => {
        console.log("startGameAsync.rejected", action.error)
      })

      .addCase(finishGameAsync.fulfilled, (state, action) => {
        const game = action.payload
        console.log("finishGameAsync.fulfilled", game)
        gamesAdapter.updateOne(state.gamesAdapterState, {
          id: game.id,
          changes: game,
        })
      })
      .addCase(finishGameAsync.rejected, (state, action) => {
        console.log("finishGameAsync.rejected", action.error)
      })
  },
})

const gamesAdapterSelectors = gamesAdapter.getSelectors<RootState>(
  (state) => state.games.gamesAdapterState,
)

export const selectGamesStatus = (state: RootState) => state.games.status
export const selectGames = (state: RootState) =>
  gamesAdapterSelectors.selectAll(state)
export const selectGamesCount = (state: RootState) =>
  gamesAdapterSelectors.selectTotal(state)
export const selectGameById = (state: RootState, gameId: string) =>
  gamesAdapterSelectors.selectById(state, gameId)

export default gamesSlice.reducer
