import { useTranslation } from "react-i18next"
import { QRCodeSVG } from "qrcode.react"

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { closeShareModal, selectShareUrl } from "./sharingSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"

import styles from "./ShareModal.module.css"

export const ShareModal = (props: { modalOpen: boolean }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const shareDataUrl = useAppSelector(selectShareUrl)
  if (!shareDataUrl) {
    return
  }

  const handleClose = () => {
    dispatch(closeShareModal())
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={props.modalOpen}>
      <DialogTitle>
        {t("sharing.share")}
        <IconButton
          aria-label={t("close")}
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={styles.svgForcer}>
          <QRCodeSVG level="L" value={shareDataUrl} />
        </div>
      </DialogContent>
      <DialogActions>
        {navigator.share && (
          <Button
            onClick={() => {
              navigator.share({
                url: shareDataUrl,
              })
            }}
          >
            {t("sharing.share")}
          </Button>
        )}
        {navigator.clipboard && (
          <Button
            onClick={() => {
              navigator.clipboard.writeText(shareDataUrl)
            }}
          >
            {t("sharing.copy_clipboard")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
