import { useState } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { Alert, Box, Button, Stack, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { GameStatus } from "./types"
import { shareGameAsync } from "../sharing/sharingSlice"
import { finishGameAsync, selectGameById, startGameAsync } from "./gamesSlice"
import { selectPlayerById } from "../players/playersSlice"
import { selectUserPlayerId } from "../common/userSettingsSlice"

import { Paper } from "../common/Paper"
import { PlayerColorSelect } from "../players/PlayerColorSelect"
import { PlayerAvatar } from "../players/PlayerAvatar"
import { PlayerFinishOrderSelect } from "../players/PlayerFinishOrderSelect"
import { PLAYER_COLORS } from "../players/types"

export const GameDetails = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { gameId } = useParams()

  const userPlayerId = useAppSelector(selectUserPlayerId)
  const game = useAppSelector((state) => selectGameById(state, gameId ?? ""))
  const gameCreator = useAppSelector((state) =>
    selectPlayerById(state, game?.creator ?? ""),
  )

  const [playerColorSelections, setPlayerColorSelections] = useState(
    {} as { [playerId: string]: string },
  )
  const [playerFinishOrderSelections, setPlayerFinishOrderSelections] =
    useState({} as { [playerId: string]: number })

  if (!game) {
    return (
      <Paper>
        <Typography variant="h4" gutterBottom>
          {t("games.not_found")}
        </Typography>
      </Paper>
    )
  }

  const usedColorIds = Object.values(playerColorSelections)
  const availableColorIds = Object.keys(PLAYER_COLORS).filter(
    (f) => !usedColorIds.includes(f),
  )

  const usedFinishOrders = Object.values(playerFinishOrderSelections)
  const availableFinishOrders = [...Array(game.players.length).keys()]
    .map((f) => f + 1)
    .filter((f) => !usedFinishOrders.includes(f))

  const isGameCreator = userPlayerId === game.creator
  const shouldAskForGameUpdate =
    game.status !== GameStatus.Finished && !isGameCreator

  const handleStartGame = () => {
    dispatch(
      startGameAsync({
        id: game.id,
        players: Object.keys(playerColorSelections).map((id) => ({
          id,
          colorId: playerColorSelections[id],
        })),
        startedOn: new Date().getTime(),
      }),
    )
  }

  const handleFinishGame = () => {
    dispatch(
      finishGameAsync({
        id: game.id,
        players: Object.keys(playerFinishOrderSelections).map((id) => ({
          id,
          finishOrder: playerFinishOrderSelections[id],
        })),
        endedOn: new Date().getTime(),
      }),
    )
  }

  const handleUpdateFinishOrder = (
    gamePlayerId: string,
    finishOrder: number,
  ) => {
    setPlayerFinishOrderSelections((prevState) => {
      let newState = { ...prevState }
      let prevPlayerFinish
      if (newState[gamePlayerId]) {
        prevPlayerFinish = newState[gamePlayerId]
        delete newState[gamePlayerId]
      }
      if (finishOrder === 0) {
        return newState
      }
      let definedPlayers = Object.keys(newState)
      let definedPlayersLength = definedPlayers.length + 1
      if (definedPlayersLength === game.players.length - 1) {
        const missingPlayerId = game.players
          .map((p) => p.id)
          .filter(
            (id) => id !== gamePlayerId && !definedPlayers.includes(id),
          )[0]
        const tAvailableFinishOrders = availableFinishOrders.slice()
        if (prevPlayerFinish) {
          tAvailableFinishOrders.push(prevPlayerFinish)
        }
        const missingFinishOrder = tAvailableFinishOrders.filter(
          (f) => f !== finishOrder,
        )[0]
        newState = {
          ...newState,
          [missingPlayerId]: missingFinishOrder,
        }
      }
      return {
        ...newState,
        [gamePlayerId]: finishOrder,
      }
    })
  }

  return (
    <Paper
      buttons={
        game && (
          <Button
            onClick={() => {
              dispatch(shareGameAsync(game))
            }}
            variant="contained"
          >
            {t("sharing.share")}
          </Button>
        )
      }
    >
      <Typography variant="h4" gutterBottom>
        {t("games.title_details")}
      </Typography>
      {shouldAskForGameUpdate && (
        <Alert severity="info">{t("games.ask_for_game_update")}</Alert>
      )}

      <Typography variant="body1" gutterBottom>
        {`${t("games.creator")}: ${
          gameCreator ? gameCreator.name : game.creator
        }`}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {`${t("games.status")}: ${game.status}`}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {`${t("games.track")}: ${game.track}`}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {`${t("games.created")}: ${new Date(game.createdOn)}`}
      </Typography>
      {game.startedOn && (
        <Typography variant="body1" gutterBottom>
          {`${t("games.startedOn")}: ${new Date(game.startedOn)}`}
        </Typography>
      )}
      {game.endedOn && (
        <Typography variant="body1" gutterBottom>
          {`${t("games.endedOn")}: ${new Date(game.endedOn)}`}
        </Typography>
      )}
      <Typography variant="body1" gutterBottom>
        {`${t("games.modules")}: ${JSON.stringify(game.modules)}`}
      </Typography>

      <Typography variant="h5" gutterBottom>
        {t("players.title")}
      </Typography>

      <Stack direction="column" spacing={2}>
        {game.players.map((gamePlayer) => (
          <GamePlayer
            key={gamePlayer.id}
            gameStatus={game.status}
            gamePlayerId={gamePlayer.id}
            availableColorIds={availableColorIds}
            gamePlayerColorId={gamePlayer.colorId}
            selectedColorId={playerColorSelections[gamePlayer.id]}
            onSelectedColorIdChange={(gamePlayerId, colorId) => {
              setPlayerColorSelections((prevState) => ({
                ...prevState,
                [gamePlayerId]: colorId,
              }))
            }}
            availableFinishOrders={availableFinishOrders}
            gameFinishOrder={gamePlayer.finishOrder}
            finishOrder={playerFinishOrderSelections[gamePlayer.id]}
            onFinishOrderChange={handleUpdateFinishOrder}
          />
        ))}
      </Stack>

      {isGameCreator && game.status === GameStatus.Starting && (
        <Button variant="contained" onClick={handleStartGame}>
          {t("games.start")}
        </Button>
      )}

      {isGameCreator && game.status === GameStatus.Started && (
        <Button variant="contained" onClick={handleFinishGame}>
          {t("games.finish")}
        </Button>
      )}
    </Paper>
  )
}

const GamePlayer = (props: {
  gameStatus: GameStatus
  gamePlayerId: string
  availableColorIds?: string[]
  gamePlayerColorId?: string
  selectedColorId: string
  onSelectedColorIdChange: (gamePlayerId: string, colorId: string) => void
  availableFinishOrders: number[]
  gameFinishOrder?: number
  finishOrder: number
  onFinishOrderChange: (gamePlayerId: string, finishOrder: number) => void
}) => {
  const player = useAppSelector((state) =>
    selectPlayerById(state, props.gamePlayerId),
  )
  if (!player) {
    throw new Error("Passed non existent player to GamePlayer")
  }
  let playerNameLabel = player.name
  if (props.gameFinishOrder) {
    playerNameLabel = `${props.gameFinishOrder} - ${player.name}`
  }

  return (
    <Grid container>
      <Grid xs={2}>
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <PlayerAvatar
            playerId={props.gamePlayerId}
            gameColorId={props.gamePlayerColorId}
          />
        </Box>
      </Grid>
      <Grid xs={11} md={6}>
        <Box height="100%" display="flex" alignItems="center">
          <Stack>
            <Typography variant="body1" gutterBottom>
              {playerNameLabel}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {props.gamePlayerId}
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid xs={12} md={4}>
        {props.gameStatus === GameStatus.Starting && (
          <PlayerColorSelect
            availableColorIds={props.availableColorIds}
            selectedColorId={props.selectedColorId}
            onChange={(colorId) => {
              props.onSelectedColorIdChange(props.gamePlayerId, colorId)
            }}
          />
        )}
        {props.gameStatus === GameStatus.Started && (
          <PlayerFinishOrderSelect
            availableFinishOrders={props.availableFinishOrders}
            selectedFinishOrder={props.finishOrder}
            onChange={(finishOrder) => {
              props.onFinishOrderChange(props.gamePlayerId, finishOrder)
            }}
          />
        )}
      </Grid>
    </Grid>
  )
}
