import { ReactNode } from "react"

import { Paper as MuiPaper, Stack } from "@mui/material"

interface PaperProps {
  children: ReactNode
  buttons?: ReactNode
}

export const PaperButtons = (props: { buttons: ReactNode }) => (
  <Stack sx={{ mt: 1 }} direction="row-reverse" spacing={1}>
    {props.buttons}
  </Stack>
)

export const Paper = (props: PaperProps) => {
  return (
    <MuiPaper
      elevation={4}
      style={{
        margin: 10,
        padding: 10,
      }}
    >
      {props.children}
      {props.buttons && <PaperButtons buttons={props.buttons} />}
    </MuiPaper>
  )
}
