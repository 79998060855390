import { Game } from "../types"
import { connectDb } from "../../../app/database"
import { GAMES_STORE_NAME } from "./GamesStore"

const gamesStore = () =>
  connectDb().then((db) => {
    const transaction = db.transaction(GAMES_STORE_NAME, "readwrite")
    return {
      db: db,
      tx: transaction,
      store: transaction.objectStore(GAMES_STORE_NAME),
    }
  })

export const getAllGames = () =>
  new Promise<Game[]>((resolve, reject) => {
    gamesStore().then(({ db, store }) => {
      const allGamesRequest = store.getAll()
      allGamesRequest.onsuccess = () => {
        const data: Game[] = allGamesRequest.result
        db.close()
        resolve(data)
      }
      allGamesRequest.onerror = (event) => {
        console.log("getAllGames error", event)
        db.close()
        reject(event)
      }
    })
  })

export const addGame = (game: Game) =>
  new Promise<void>((resolve, reject) => {
    gamesStore().then(({ db, tx, store }) => {
      const request = store.add(game)
      request.onerror = (event) => {
        console.log("addGame request.onerror", event)
        db.close()
        reject()
      }
      tx.onerror = (event) => {
        console.log("addGame tx.onerror", event)
        db.close()
        reject()
      }
      request.onsuccess = (event) => {
        console.log("addGame request.onsuccess", event)
      }
      tx.oncomplete = (event) => {
        console.log("addGame tx.oncomplete", event)
        db.close()
        resolve()
      }
    })
  })

export const updateGame = (game: Game) =>
  new Promise<void>((resolve, reject) => {
    gamesStore().then(({ db, tx, store }) => {
      const request = store.put(game)
      request.onerror = (event) => {
        console.log("updateGame request.onerror", event)
        db.close()
        reject()
      }
      tx.onerror = (event) => {
        console.log("updateGame tx.onerror", event)
        db.close()
        reject()
      }
      request.onsuccess = (event) => {
        console.log("updateGame request.onsuccess", event)
      }
      tx.oncomplete = (event) => {
        console.log("updateGame tx.oncomplete", event)
        db.close()
        resolve()
      }
    })
  })
