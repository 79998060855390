import { useTranslation } from "react-i18next"

import { Typography } from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Player } from "../players/types"
import { SharePlayerBootstrapAction } from "../sharing/types"
import { addGameAsync } from "../games/gamesSlice"
import { addPlayerAsync } from "../players/playersSlice"
import {
  clearPlayerBootstrap,
  selectShareReceivedAction,
} from "../sharing/sharingSlice"
import { nextWelcomeStep } from "../common/userSettingsSlice"

import { Paper } from "../common/Paper"
import { PlayerForm } from "../players/PlayerForm"

export const SetupPlayer = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const bootstrapAction: SharePlayerBootstrapAction = useAppSelector(
    selectShareReceivedAction,
  ) as SharePlayerBootstrapAction
  const bootstrappedPlayers = (bootstrapAction?.players ?? []).filter(
    (p) => p.id !== bootstrapAction.playerId,
  )
  const bootstrappedGames = bootstrapAction?.games ?? []

  const handleSubmit = (player: Player) => {
    dispatch(addPlayerAsync(player)).then(() => {
      dispatch(nextWelcomeStep(player.id))
      for (const player of bootstrappedPlayers) {
        dispatch(addPlayerAsync(player))
      }
      for (const game of bootstrappedGames) {
        dispatch(addGameAsync(game))
      }
      dispatch(clearPlayerBootstrap())
    })
  }

  return (
    <Paper>
      <Typography variant="h4" gutterBottom>
        {t("welcome.player_setup")}
      </Typography>
      <PlayerForm onSubmit={handleSubmit} />
    </Paper>
  )
}
