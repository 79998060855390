import { useTranslation } from "react-i18next"

import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { closeReceiveModal, selectShareReceivedAction } from "./sharingSlice"

export const ReceiveModal = (props: { modalOpen: boolean }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const receivedAction = useAppSelector(selectShareReceivedAction)
  if (!receivedAction) {
    return
  }

  const handleClose = () => {
    dispatch(closeReceiveModal())
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={props.modalOpen}>
      <DialogTitle>
        {t("sharing.received")}
        <IconButton
          aria-label={t("close")}
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <pre>{JSON.stringify(receivedAction, null, 4)}</pre>
      </DialogContent>
    </Dialog>
  )
}
