import { PlayersStore } from "../features/players/db/PlayersStore"
import { GamesStore } from "../features/games/db/GamesStore"

interface DbStoreMigration {
  version: number
  migrate: (objectStore: IDBObjectStore) => void
}

export interface DbStore {
  name: string
  migrations: DbStoreMigration[]
}

const DB_NAME = "HeatTrack"
const DB_VERSION = 3

const stores = [PlayersStore, GamesStore] as DbStore[]

export const connectDb = () =>
  new Promise<IDBDatabase>((resolve, reject) => {
    const DBOpenRequest = window.indexedDB.open(DB_NAME, DB_VERSION)

    // Register two event handlers to act on the database being opened successfully, or not
    DBOpenRequest.onerror = (event) => {
      console.log("connectDb onerror", event)
      reject()
    }

    DBOpenRequest.onsuccess = () => {
      const db = DBOpenRequest.result
      db.onclose = () => {
        console.log("indexedDB db closing")
      }
      resolve(db)
    }

    DBOpenRequest.onupgradeneeded = (event) => {
      console.log("connectDb onupgradeneeded")
      const oldVersion = event.oldVersion
      const newVersion = event.newVersion
      const openRequest = event.target as IDBOpenDBRequest

      if (newVersion === null) {
        // Database is being deleted.....? abort
        reject()
        return
      }

      const db = openRequest.result
      db.onerror = (event) => {
        console.log("indexedDB upgradeneeded onerror", event)
        reject()
      }

      stores.forEach((store) => {
        const storeMinVersion = store.migrations[0].version
        let objectStore: IDBObjectStore
        if (isNaN(oldVersion) || oldVersion < storeMinVersion) {
          objectStore = openRequest.result.createObjectStore(store.name, {
            keyPath: "id",
          })
        } else {
          let transaction = openRequest.transaction
          if (!transaction) {
            reject("Failed getting transaction for upgrading store")
            return
          }
          objectStore = transaction.objectStore(store.name)
        }

        store.migrations
          .filter((migration) => migration.version > oldVersion)
          .forEach((migration) => migration.migrate(objectStore))
      })
    }
  })

export const clearDb = () =>
  new Promise<void>((resolve) => {
    connectDb().then((db) => {
      if (db.objectStoreNames.length === 0) {
        resolve()
        return
      }

      let successCounter = db.objectStoreNames.length
      const tx = db.transaction(db.objectStoreNames, "readwrite")
      for (let objectStoreName of db.objectStoreNames) {
        const clearRequest = tx.objectStore(objectStoreName).clear()
        clearRequest.onerror = (event) => {
          console.log(`Failed clearing objectStore ${objectStoreName}`, event)
        }
        clearRequest.onsuccess = () => {
          console.log(`Cleared objectStore ${objectStoreName}`)
          successCounter -= 1
          if (successCounter <= 0) {
            resolve()
          }
        }
      }
    })
  })
