import { Link as RouterLink, useNavigate } from "react-router-dom"

import { Button, List, Typography } from "@mui/material"

import { useAppSelector } from "../../app/hooks"
import { selectGames } from "./gamesSlice"

import { Paper, PaperButtons } from "../common/Paper"
import { GameListItem } from "./GameListItem"
import { useTranslation } from "react-i18next"

export const Games = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const games = useAppSelector(selectGames)

  return (
    <Paper>
      <Typography variant="h4" gutterBottom>
        {t("games.title")}
      </Typography>
      <PaperButtons
        buttons={
          <Button component={RouterLink} to="/newgame" variant="contained">
            {t("games.add")}
          </Button>
        }
      />
      <List>
        {games.map((game) => (
          <GameListItem
            key={game.id}
            game={game}
            onClick={() => {
              navigate(`/games/${game.id}`)
            }}
          />
        ))}
      </List>
    </Paper>
  )
}
