import { createBrowserRouter, Navigate } from "react-router-dom"

import { MainLayout } from "../features/common/MainLayout"
import { Players } from "../features/players/Players"
import { Games } from "../features/games/Games"
import { NewGame } from "../features/games/NewGame"
import { Settings } from "../features/settings/Settings"
import { GameDetails } from "../features/games/GameDetails"
import { PlayerDetails } from "../features/players/PlayerDetails"

export const router = createBrowserRouter([
  {
    path: "",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/players" />,
      },
      {
        path: "players",
        element: <Players />,
      },
      {
        path: "players/:playerId",
        element: <PlayerDetails />,
      },
      {
        path: "newgame",
        element: <NewGame />,
      },
      {
        path: "games",
        element: <Games />,
      },
      {
        path: "games/:gameId",
        element: <GameDetails />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },
])
